$(function() {
  autocompleter();
});

function autocompleter() {
  $('.autocompleter').each(function() {
    var path                 = $(this).data('path');
    var label_field_selector = $(this).data('label-field-selector');
    var id_field_selector    = $(this).data('id-field-selector');
    var spinner_selector     = $(this).next('.loading-spinner');

    $(this).autocomplete({
      source: function(request, response) {
        spinner_selector.show();
        const params = { term: request.term }
        if($('#labor-order-branch-id') !== null) params['branch_id'] = Number($('#labor-order-branch-id').val())

        $.getJSON(`/api/v1/autocompleters/${path}`, params, function(data) {
          var results = [];
          $.each(data, function(index, item) {
            results.push({
              label: item.label,
              value: item.value
            });
            Object.keys(item).forEach(function(key) {
              if (key !== 'label') {
                var data_key = `data-${key}`.replace(/_/, '-')
                results[results.length-1][data_key] = item[key]
              }
            });
          });
          response(results);
          spinner_selector.hide();
        });
      },
      minLength: 1,
      select: function(event, ui) {
        var item = ui.item;
        Object.keys(item).forEach(function(key) {
          if (key !== 'label') {
            $(this).attr(key, item[key]);
          }
        }.bind(this));
        $(label_field_selector).val(ui.item.label);
        $(id_field_selector).val(ui.item.value);
        return false;
      }
    });
  });
}
