import Select2 from 'select2';
import 'select2/dist/css/select2.css';

Select2($)

$(function() {
  applyMultiselect();
});

function applyMultiselect() {
  var basicMultiselect = $('.basic-multiselect')

  basicMultiselect.each(function(_, dropdown) {
    var dropdownEl    = $(dropdown)
    var placeholder   = dropdownEl.attr('placeholder')          || ''
    var allString     = dropdownEl.attr('data-all-string')      || ''
    var groupString   = dropdownEl.attr('data-group-string')    || ''
    var tagsMaxNumber = dropdownEl.attr('data-tags-max-number') || 3

    var select2Dropdown = dropdownEl.select2({
      closeOnSelect : false,
      allowClear:     false,
      tags:           false,
      placeholder:    placeholder
    });

    var inputAdded = false;

    select2Dropdown.on('select2:open', function(e) {
      e.preventDefault();

      var dropdownEl = $(e.target).data('select2').dropdown.$dropdown;

      if (!inputAdded) {
        var $dropdown    = $(dropdownEl);
        var $searchField = $dropdown.find('.select2-search__field');

        var inputField =              '<div class="input-group">';
        inputField     = inputField + '  <div class="input-group-prepend">';
        inputField     = inputField + '    <span class="input-group-text custom-multiselect-search-input-icon"><i class="fa fa-search"></i></span>';
        inputField     = inputField + '  </div>';
        inputField     = inputField + '<input type="text" class="custom-multiselect-search-input form-control" />';
        inputField     = inputField + '</div>';

        var $textInput = $(inputField);

        $searchField.replaceWith($textInput);

        $dropdown.prepend($textInput);

        inputAdded = true;
      }

      $('.custom-multiselect-search-input').val('');
      $('.custom-multiselect-search-input').focus();
    });

    var currentSelectedOptions = dropdownEl.val()
    var select2Instance        = dropdownEl.data('select2');
    var numberOfOptions        = $(dropdown).find('option').length - 1

    if (!allIsSelected(currentSelectedOptions) && currentSelectedOptions.length == numberOfOptions) {
      $(dropdownEl.find('option')[0]).prop('selected', true);
    }

    updateInputDisplay(select2Instance, allString, groupString, tagsMaxNumber);

    var previousSelectedOptions = select2Dropdown.val();

    select2Dropdown.on('change', function(e) {
      var currentSelectedOptions = $(this).val();
      var select2Instance        = $(this).data('select2');
      var numberOfOptions        = $(this).find('option').length - 1
      var unselectedOptions      = [];

      $(this).find('option:not(:selected)').each(function() {
        var optionValue = $(this).val();
        unselectedOptions.push(optionValue);
      });

      if (allWasSelected(previousSelectedOptions, currentSelectedOptions)) {
        $(this).find('option:not(:selected)').prop('selected', true);
      }else if (allWasUnselected(previousSelectedOptions, currentSelectedOptions)) {
        $(this).find('option:selected').prop('selected', false);
      }else if (allIsSelected(currentSelectedOptions) && currentSelectedOptions.length <= numberOfOptions) {
        $($(this).find('option')[0]).prop('selected', false);
      }else if (!allIsSelected(currentSelectedOptions) && currentSelectedOptions.length == numberOfOptions) {
        $($(this).find('option')[0]).prop('selected', true);
      }

      currentSelectedOptions  = $(this).val();
      previousSelectedOptions = currentSelectedOptions;

      updateInputDisplay(select2Instance, allString, groupString, tagsMaxNumber);
    });

    // Autocomplete
    $(document).on('input', '.custom-multiselect-search-input', function(e) {
      e.preventDefault
      var searchText = $(this).val().toLowerCase();

      $('.select2-results__option').hide();


      if (searchText === '') {
        $('.select2-results__option').show();
        return;
      }

      $('.select2-results__option').hide().each(function(_, element) {
        if ($(element).text().toLowerCase().indexOf(searchText) > -1){
          $(element).show();
        }else {
          $(element).hide();
        }
      });

      $('.basic-multiselect').trigger('change')

      $('.select2-results__options').trigger('scroll');
    });

    // Attempt to stop the toggling of the dropdown
    $(document).on('click', '.select2-selection.select2-selection--multiple, .select2-selection__choice__remove', function(e) {
      var $clickedElement   = $(e.target);
      var $multipleElement  = $clickedElement.closest('.select2-selection.select2-selection--multiple');
      var xButtonWasClicked = $clickedElement.hasClass('select2-selection__choice__remove') || $($clickedElement.parent()).hasClass('select2-selection__choice__remove')

      if (xButtonWasClicked) {
        // console.log('BOTÃO FOI CLICADO');
      }
    });
  });
}

function allWasSelected(previousSelectedOptions, currentSelectedOptions) {
  return !previousSelectedOptions.includes('all') && currentSelectedOptions.includes('all')
}

function allWasUnselected(previousSelectedOptions, currentSelectedOptions) {
  return previousSelectedOptions.includes('all') && !currentSelectedOptions.includes('all')
}

function allIsSelected(currentSelectedOptions) {
  return currentSelectedOptions.includes('all')
}

function updateInputDisplay(select2Instance, allString, groupString, tagsMaxNumber) {
  var selectedOptions = select2Instance.data().length;
  var selectedValues  = select2Instance.data().map(function(option) {
    return option.id;
  });

  if (selectedValues.includes('all')) {
    select2Instance.$container.find('.select2-selection__rendered').text(`${allString} (${selectedOptions - 1})`);
    return;
  }

  if (selectedOptions > tagsMaxNumber) {
    select2Instance.$container.find('.select2-selection__rendered').text(`${groupString} (${selectedOptions})`);
  } else if (selectedOptions == 0) {
    select2Instance.$container.find('.select2-selection__rendered').text('');
  }
}
