

$(function() {
  function toggle_scrollbar() {
    if ($("body").css('overflow') == 'hidden') {
      $('body').css('overflow', 'initial');
    } else {
      $('body').css('overflow', 'hidden');
    }
  }

  $('#topbar-burger-btn').on('click', function () {
    $('#sidebar').toggleClass('active');
    $('#sidebar-content-overlay').toggle();
    toggle_scrollbar()
  });

  $('#sidebar-content-overlay').on('click', function () {
    $('#sidebar').toggleClass('active');
    $('#sidebar-content-overlay').toggle();
    toggle_scrollbar()
  });

  applyAutocomplete();

  $('#sidebar-search-input').on('keydown', function(event) {
    var keyCode = event.which || event.keyCode;

    if (keyCode === 13 && $(this).val() !== '') {
      var firstVisibleLink = $('#sidebar-content li:visible').first().find('a');

      if (firstVisibleLink.length > 0) {
        firstVisibleLink[0].click();
      }

      return false;
    }
  });
});

function applyAutocomplete() {
  $('#sidebar-search-input').on('input', function() {
    var searchText = $(this).val().toLowerCase();

    $('.sidebar-section').hide();

    if (searchText === '') {
      $('.sidebar-section').show();
      $('.sidebar-section > li').show();
      return;
    }

    $('.sidebar-section li').hide().filter(function() {
      return $(this).text().toLowerCase().indexOf(searchText) > -1;
    }).show().closest('.sidebar-section').show();
  });
}
