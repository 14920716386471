import Inputmask from 'inputmask';

export function applyPhoneMasks() {
  applyAllCountriesPhoneMasks();
  detectCountryCodeChange();
}

function applyAllCountriesPhoneMasks() {
  var inputElements = document.querySelectorAll('.usa-phone-mask');

  inputElements.forEach(function(inputElement) {
    Inputmask({ mask: '(999) 999-9999' }).mask(inputElement);
  });

  var inputElements = document.querySelectorAll('.br-phone-mask');

  inputElements.forEach(function(inputElement) {
    Inputmask({ mask: '(99) 9999[9]-9999' }).mask(inputElement);
  });
}

function detectCountryCodeChange() {
  $('.country-code-select').on('change', function() {
    console.log('$(this)', $(this))
    var selectedValue = $(this).val();
    var phoneNumberInput = $(this).closest('.phone-number-field-group').find('.phone-number-input');

    phoneNumberInput.removeClass(function(index, className) {
      return (className.match(/[^\s]+-phone-mask/g) || []).join(' ');
    });

    if (selectedValue === '+1') {
      phoneNumberInput.addClass('usa-phone-mask');
    } else if (selectedValue === '+55') {
      phoneNumberInput.addClass('br-phone-mask');
    }

    applyAllCountriesPhoneMasks();
  });
}
