
import 'jquery-ui/ui/widgets/datepicker';

$(function() {
  birthdate_picker();
});

function birthdate_picker() {
  $('.birthdate-selector').each(function() {
    const options = {
      showOtherMonths:   true,
      selectOtherMonths: false,
      changeMonth:       true,
      changeYear:        true,
      maxDate:           0,
      yearRange: `${((new Date).getFullYear() - 110).toString()}:+0`,
      ...(<any>window).datePickerI18nOptions
    };

    (<any>$(this)).datepicker(options);
  });
}
