import '@fortawesome/fontawesome-free/js/all.js';
import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';
import 'bootstrap'
import './vendor';
import 'jquery-ui/dist/jquery-ui';
import './sidebar';
import './components/datepicker';
import './components/money_mask';
import './components/autocompleter';
import './components/multiselect';
import { applyPhoneMasks } from './phone_masks';
import { applyDateMask } from './date_mask';

$(function () {
  fadeAlert();
  applyMoneyMasks();
  applyOnlyNumeric();
  topBarBurgerBtn();
  applyLoadingScreen();
  applyPhoneMasks();
  applyDateMask();
});

function fadeAlert() {
  setInterval(function () {
    $(".alert").fadeOut(2000);
  }, 3000);
}

function applyMoneyMasks() {
  $(".currency-input-field, .percentage-input-field").maskMoney();
  $.each(
    $(".currency-input-field, .percentage-input-field").toArray(),
    function (index, element) {
      $(element).focus();
      $(element).blur();
    }
  );
}

function applyOnlyNumeric() {
  $(".only-numeric").on("input", function () {
    var new_value = ($(this).val() as string).replace(/\D+/g, "");
    $(this).val(new_value);
  });
}

function topBarBurgerBtn() {
  $('#topbar-burger-btn').on('click', function() {
    $('#sidebar-search-input').focus();
  });
}

function applyLoadingScreen() {
  $('.show-loading-screen, button[type="submit"], a[href]').filter(function() {
    if($(this).is('a') && $(this).attr('href') != undefined) {
      return !$(this).attr('href').match(/^#.*/);
    }
    return true;
  }).on('click', function(event) {
    if (!event.ctrlKey) {
      $('#loading-screen').show();
    }
  });
}

Rails.start();
ActiveStorage.start();
